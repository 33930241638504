import React, { useEffect, useState } from 'react'
import { AbortController } from 'node-abort-controller'
import makeRequest from '../../helpers/make-request'
import LeadershipView from './leadership-detail-view'
import { useTranslation } from 'react-i18next'

const LeadershipController = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataLeadership, setDataLeadership] = useState([])
  const controller = new AbortController()

  const { i18n } = useTranslation();
  // catch with useEffect so the data will be contained
  const getLeadershipData = async () => {
    setLoading(true)
    const fields = 'id,title,content,slug,acf,thumbnail,featured_media'


    // fetch the data with makerequest
    makeRequest({
      endPoint: 'leadership',
      signal: controller.signal,
      params: {
        fields,
        slug,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataLeadership(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getLeadershipData()
    return () => {
      controller.abort()
    }
  }, [slug])

  const viewProps = {
    data: dataLeadership,
    loading,
  }
  return (
    <LeadershipView {...viewProps}/>
  )
}

export default LeadershipController
