import _ from "lodash"
import React from "react"
import "./leadership-detail-styles.scss"
import IconLinkedin from "../../assets/icons/seasongroup_icons_socmed_linkedin.svg"
import {
  Seo,
  Spinner,
  Carousel,
  CardTeam,
  GradientBanner,
} from "../../components"

const LeadershipDetailView = ({ data, loading }) => {
  if (loading) {
    return <Spinner loading={loading} />
  }
  const team = _.get(data, "acf.rows[0]")
  const linkLinkedin = _.get(data, "acf.linkedin", undefined)
  return (
    <div className="leadershipDetail__container-fluid container-fluid">
      <div className="leadershipDetail__container">
        <Seo title={_.get(data, "title")} />
        <div className="leadershipDetail__box">
          <div className="container-xxl">
            <div className="row">
              <div className="col-md-6 col-12 leadershipDetail__content">
                <h1>{_.get(data, "title")}</h1>
                <p className="leadershipDetail__position">
                  {_.get(data, "acf.position")}
                </p>
                {linkLinkedin ? (
                  <a
                    href={_.get(data, "acf.linkedin")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="leadershipDetail__icon">
                      <IconLinkedin width="16" fill="#fff" />
                    </span>
                  </a>
                ) : null}
                <div className="leadershipDetail__image mobile">
                  <img
                    src={_.get(data, "thumbnail.url")}
                    alt={_.get(data, "thumbnail.alt")}
                  />
                </div>
                <div
                  className="rich-text"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>

              <div className="col-md-6 col-12 leadershipDetail__image">
                <img
                  src={_.get(data, "thumbnail.url")}
                  alt={_.get(data, "thumbnail.alt")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-xxl">
          <div>
            {team ? (
              <div className="">
                <div className="carousel__tagline">
                  <h2>{team.title}</h2>
                </div>
                <div>
                  <Carousel
                    data={team.list}
                    sToShow={3}
                    sToScroll={3}
                    arrows={true}
                  >
                    {team.list.map((teammate, i) => (
                      <div key={i} className="cardTeam__box">
                        <CardTeam data={teammate} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <GradientBanner />
    </div>
  )
}
export default LeadershipDetailView
